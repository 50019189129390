const toggleMenu = event => {
  const navIconClasses = event.currentTarget.classList;
  const navMenuClasses = document.getElementById("navMenu").classList;

  if (navIconClasses.contains("open")) {
    navIconClasses.remove("open");
  } else {
    navIconClasses.add("open");
  };

  if (navMenuClasses.contains("open")) {
    navMenuClasses.remove("open");
  } else {
    navMenuClasses.add("open");
  }    
}

export default () => {
  const navIconEl = document.getElementById('navIcon');

  navIconEl.addEventListener('click', event => {
    toggleMenu(event);
  });
}