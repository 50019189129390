const setImageSources = image => {
  const {
    dataset: { src, srcSet }
  } = image;

  if (src) {
    image.setAttribute('src', src);
  }

  if (srcSet) {
    image.setAttribute('srcSet', srcSet);
  }
};

const setImagesAttr = images => {
  images.forEach(img => {
    setImageSources(img);
  });
};

const lazyImages = () => {
  const images = [...document.querySelectorAll('img[loading="lazy"]')];

  // Browser natively supports lazy images
  if ('loading' in HTMLImageElement.prototype) {
    setImagesAttr(images);
    // if not, use intersection observer instead
  } else
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window
  ) {
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          setImageSources(img);

          img.setAttribute('data-action', '');
          imgObserver.unobserve(img);
        }
      });
    });

    images.forEach(imgEl => imageObserver.observe(imgEl));
    // Browser doesn't support either therefore load all images
  } else {
    setImagesAttr(images);
  }
};

/* IE11 doesn't support object fit therefore add it as a background image */
const objectFitImages = () => {

  const coverImages = document.querySelectorAll('img[loading="lazy"]');
  [...coverImages].forEach(img => {
    img.parentElement.setAttribute(
      'style',
      `background: no-repeat url(${img.getAttribute(
        'src'
      )}); background-size: cover; background-position: center center;`
    );

    img.classList.add('opacity-0');
  });
};

export { lazyImages, setImageSources, objectFitImages };
